<template>
  <div class="Me">
    <van-cell-group>
      <van-cell :title="userName" class="info_user" label-class="info_user" :label="location">
        <template #icon>
          <van-image
            round
            width="50px"
            height="50px"
            :src="avatar"
          />
        </template>
      </van-cell>
    </van-cell-group>

    <van-cell-group style="margin-top:5%;">
      <van-cell title="个人信息修改" is-link to="/me/info" />
      <van-cell title="评估信息(缺失/错误)反馈" is-link to="/feed_back" />
      <van-cell title="意见&建议" is-link to="#" @click="tips()"/>
      <van-cell title="帮助中心" is-link to="#" @click="tips()"/>
    </van-cell-group>

    <van-button type="info" round block style="width:90%;align:center;margin:15px 20px;" @click="quit()">退出登录</van-button>
  </div>
</template>

<script>
export default {
  name: "Me",
  data() {
    return {
      userName: "",
      location:"",
      avatar:"",
    };
  },
  created(){
    this.getUserInfo();
  },
  mounted(){
  },
  methods:{
    getUserInfo(){
      var that = this;
      this.$axios.get("/center").then(function(res){
        that.userName = res.data.mobile;
        that.location = res.data.province+' '+res.data.city+' '+res.data.area;
        that.avatar = res.data.avatar == null ? 'https://www.321pg.com/uploads/images/default_160x160.jpeg' : res.data.avatar;
      },function(err){})
    },
    tips(){
      this.$toast.fail('页面开发中');
    },
    quit(){
      var that = this;
      this.$axios.get("/quit").then(function(res){
        if(res.data.code == '000'){
          that.$toast.success(res.data.msg);
          that.$router.push('/login');
        }
      },function(err){})
    }
  }
};
</script>
<style scoped>
/* #icon{
  margin-left:30px;
} */
.info_user{
  color:white;
  background-color:dodgerblue; 
}
.van-cell__title{
  text-align:left;
  margin-left:10px;
}
</style>