import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import axios from 'axios'
import 'lib-flexible/flexible'
import './plugins/vant.js'
import './assets/iconfont/iconfont.js'
import '../public/static/iconfont/iconfont.css'
import {Select} from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'; 
import 'vant/lib/index.css';
import { NavBar } from 'vant'
import { Tabbar, TabbarItem } from 'vant';
import { Button } from 'vant'
import { NoticeBar } from 'vant';
import { Picker } from 'vant';
import { Icon } from 'vant';
import { Field } from 'vant';
import { Form } from 'vant';
import { Popup } from 'vant';
import { Area } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Empty } from 'vant';
import { List } from 'vant';
import { PullRefresh } from 'vant';
import { Grid, GridItem } from 'vant';
import { Image as VanImage } from 'vant';
import { Col, Row } from 'vant';
import { Card } from 'vant';
import { Toast } from 'vant';
import { NumberKeyboard } from 'vant';

Vue.use(NumberKeyboard);
Vue.use(Toast);
Vue.use(Card);
Vue.use(Col);
Vue.use(Row);
Vue.use(VanImage);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Empty);
Vue.use(Select);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Form);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Picker);
Vue.use(NoticeBar);
Vue.use(NavBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Button)


axios.defaults.baseURL = 'https://www.321pg.com/mobile/';
axios.defaults.withCredentials=true;
// axios.defaults.headers.origin = 'https://h5.321pg.com';
// axios.defaults.headers.token = "--------------------";

// Vue.config.productionTip = false
Vue.prototype.$axios= axios

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
