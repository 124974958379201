<template>
  <van-form @submit="onSubmit">
    <van-field
      readonly
      clickable
      required
      name="area"
      :value="areaValue"
      label="地区选择"
      placeholder="点击选择省市区"
      @click="showArea = true"
    />
    <van-popup v-model="showArea" position="bottom">
      <van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
    </van-popup>
    <van-field
      required
      v-model="mobile"
      name="mobile"
      type="number"
      label="手机号"
      placeholder="请输入手机号"
    />
    <div style="margin: 16px;">
      <van-button round block type="info" native-type="submit">提交</van-button>
    </div>
  </van-form>
</template>
<script>
import AreaList from "../assets/Area.json";
import qs from "qs";
export default {
  data() {
    return {
      mobile: "",
      countyCode: "",
      cityCode: "",
      provinceCode: "",
      showArea: false,
      areaList: AreaList,
      areaValue: "",
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo(){
      var that = this;
      this.$axios.get('/center').then(function(res){
        that.mobile = res.data.mobile !== null || res.data.mobile !== ''? res.data.mobile : '' ;
        that.areaValue = res.data.province !== null || res.data.province !== '' ?  res.data.province+'-'+res.data.city+'-'+res.data.area : '';  
      },function(err){});
    },
    //>省市区确认
    onConfirm(values) {
      this.areaValue = values.map((item) => item.name).join("-");
      this.countyCode = values[2].code;
      this.cityCode = values[1].code;
      this.provinceCode = values[0].code;
      this.showArea = false;
    },
    //>提交
    onSubmit(values) {
      var that = this;
      values.province = this.provinceCode;
      values.city = this.cityCode;
      values.area = this.countyCode;
      this.$axios.post("/saveuserinfo", qs.stringify(values)).then(
        function (response) {
          if (response.data.code == "500") {
            that.$toast({
              message: response.data.msg,
              icon: "warn-o",
            });
            that.$router.push(res.data.url);
          } else {
            that.$toast.success(response.data.msg);
            that.$router.push(res.data.url);
          }
        },
        function (err) {}
      );
    },
  },
};
</script>
<style scoped>
</style>