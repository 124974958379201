<template>
  <div class="history">
    <van-empty description="暂无查询记录" v-show="emptyList" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text=" —— 我也是有底线的 —— "
        @load="onLoad"
        :offset="10"
      >
        <van-cell-group v-for="(item,index) in listData" :key="index">
          <van-cell
            :title="item.target_name"
            :value="'总价:'+item.total_price"
            :label="'查询时间:'+item.created_at"
            @click="detail(item.fe_id)"
          />
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="show" closeable style="width:100%;height:100%;">
      <van-grid :border="false" :column-num="1">
        <van-grid-item>
          <div style="border:1px solid lightgray;width:100%;border-radius:5px;">
            <div
              style="background-color:dodgerblue;width:100%;height:150px;border-radius:5px 5px 0 0;"
            >
              <div style="text-align:center;color:white;padding-top:30px;font-size:15px;">抵押价评估总价:</div>
              <div
                style="text-align:center;color:white;font-weight:bolder;font-size:30px;"
              >{{totalPrice}}</div>
              <div
                style="text-align:center;font-size:13px;color:white;"
              >查询时间:{{createdAt}}</div>
            </div>
            <div
              style="width:100%;height:20px;margin-top:5px;font-size:12px;text-align:center;display:flex;flex-direction:row;"
            >
              <div
                style="float:left;border-right:1px solid lightgray;flex:1"
              >单价(元/㎡) {{unitPrice}}</div>
              <div style="text-align:center;flex:1">面积(㎡) {{buildingArea}}</div>
            </div>
          </div>
          <div style="width:100%;">
            <div style="background-color:lightgray;width:100%;">
              <span style="padding-left:10px;font-size:15px;font-weight:bold;text-align:left;">评估对象信息:</span>
            </div>
            <van-cell-group>
              <van-cell title="小区" :value="eName" @click="jumpDetail(eId,'estate')" />
              <van-cell title="楼幢" :value="bNo" @click="jumpDetail(bId,'building')" />
              <van-cell title="房号" :value="rNo" @click="jumpDetail(rId,'room')" />
              <van-cell title="地址" :value="address" />
              <van-cell title="年份" :value="year" />
              <van-cell title="楼层" :value="floor" />
            </van-cell-group>
          </div>
        </van-grid-item>
      </van-grid>
    </van-popup>
    <van-popup v-model="targetDetailShow" closeable style="width:100%;height:100%;">
      <van-grid :border="false" :column-num="1">
        <van-grid-item>
          <div style="margin:0 0 10px 0;width:100%;" v-if="feType === 'estate' || feType === 'building'">
            <div style="height:300px;width:100%;font-weight:bold;text-align:center;" class="map">
            <iframe frameborder="no" border="0" style="width:100%;height:100%;margin:0 auto;" :src="ifSrc" >
            </iframe>
            </div>
        </div>
          <div style="width:100%;">
            <van-cell-group v-if="feType === 'estate'">
              <van-cell title="小区名称" :value="eName" @click="jumpDetail(eId,'estate')" />
              <van-cell title="开发商" :value="developer" @click="jumpDetail(bId,'building')" />
              <van-cell title="物业公司" :value="propertyName" @click="jumpDetail(rId,'room')" />
              <van-cell title="小区地址" :value="address" />
              <van-cell title="用地面积" :value="buildingArea" />
            </van-cell-group>
            <van-cell-group v-if="feType === 'building'">
              <van-cell title="小区名称" :value="eName" @click="jumpDetail(eId,'estate')" />
              <van-cell title="楼幢编号" :value="bNo" @click="jumpDetail(bId,'building')" />
              <van-cell title="是否电梯" :value="limitNum" @click="jumpDetail(rId,'room')" />
              <van-cell title="建筑年代" :value="year" />
              <van-cell title="楼房结构" :value="structure" />
              <van-cell title="地上面积" :value="upFloorArea" />
              <van-cell title="地下面积" :value="downFloorArea" />
            </van-cell-group>
            <van-cell-group v-if="feType === 'room'">
              <van-cell title="小区名称" :value="eName" @click="jumpDetail(eId,'estate')" />
              <van-cell title="楼幢编号" :value="bNo" @click="jumpDetail(bId,'building')" />
              <van-cell title="房号" :value="rNo" @click="jumpDetail(rId,'room')" />
              <van-cell title="面积" :value="buildingArea" />
              <van-cell title="用途" :value="usedWays" />
              <van-cell title="逻辑楼层" :value="floor" />
              <van-cell title="物理楼层" :value="physicalFloor" />
              <van-cell title="房屋朝向" :value="orientation" />
              <van-cell title="户型" :value="roomUnit" />
            </van-cell-group>
          </div>
        </van-grid-item>
      </van-grid>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "History",
  data() {
    return {
      emptyList: false,
      listData: [],
      loading: false,
      finished: false,
      refreshing: false,
      list: 0,
      page: 1,
      show: false,
      targetDetailShow:false,
      eId:'',
      bId:'',
      rId:'',
      eName:'',
      bNo:'',
      rNo:'',
      address:'',
      year:'',
      floor:'',
      totalPrice:'',
      createdAt:'',
      unitPrice:'',
      buildingArea:'',
      feId:'',
      feType:'',
      ifSrc:'',
      propertyName:'',
      developer:'',
      limitNum:'',
      structure:'',
      upFloorArea:'',
      downFloorArea:'',
      usedWays:'',
      physicalFloor:'',
      orientation:'',
      roomUnit:'',
    };
  },
  created() {
    //创建组件时，加载第1页数据
    this.getList();
  },
  methods: {
    detail(fe_id) {
      this.show = true;
      var that = this;
      this.$axios
        .get("/evaluator_detail?fe_id=" + fe_id)
        .then(
          function (res) {
            that.eId = res.data.e_id;
            that.bId = res.data.b_id;
            that.rId = res.data.r_id;
            that.eName = res.data.e_name;
            that.bNo = res.data.building_no;
            that.rNo = res.data.room_no;
            that.address = res.data.address === null ? '暂无数据' : res.data.address;
            that.year = res.data.building_year;
            that.floor = res.data.logic_floor > 0 ? res.data.logic_floor : '暂无数据';
            that.totalPrice = res.data.total_price;
            that.createdAt = res.data.created_at;
            that.unitPrice = res.data.unit_price;
            that.buildingArea = res.data.building_area > 0 ? res.data.building_area : '暂无数据';
          },
          function (err) {
            console.log(err);
          }
        );
    },
    getList() {
      var that = this;
      this.$axios
        .get("/list?limit=10&page=" + that.page)
        .then(
          function (res) {
            if (that.refreshing) {
              that.list = [];
              that.refreshing = false;
            }
            that.page++;
            if(JSON.stringify(that.listData) == JSON.stringify(res.data.data)){
              that.listData = res.data.data;
            }else{
              that.listData = that.listData.concat(res.data.data);
            }
            that.emptyList = that.listData.length === 0 ? true : false;
            that.list = res.data.num;
            that.loading = false;
            if (that.listData.length >= that.list) {
              that.finished = true;
            }
          },
          function (err) {
            console.log(err);
          }
        );
    },
    // 列表加载
    onLoad() {
      setTimeout(() => {
        this.getList();
        this.loading = true;
      }, 500);
    },
    onRefresh() {
      var that = this;
      setTimeout(() => {
        // 重新初始化这些属性
        that.loading = true;
        that.list = [];
        that.page = 1;
        that.loading = false;
        that.finished = false;
        that.emptyList = false;
        // 请求信息
        that.getList();
      }, 500);
    },
    jumpDetail(id,type){
      this.targetDetailShow = true;
      this.feId = id;
      this.feType = type;
      this.ifSrc = "https://www.321pg.com/mobile/mobile_map?id="+id+"&type="+type;
      var that = this;
      this.$axios.get("/target_detail?id="+id+"&type="+type).then(function(res){
        if(type === 'estate'){
          that.propertyName = res.data.property_name;
          that.developer = res.data.developer;
        }
        if(type === 'building'){
          that.limitNum = res.data.limit_num > 0 ? '是' : '否';
          that.structure = res.data.structure;
          that.upFloorArea = res.data.up_floor_area > 0 ? res.data.up_floor_area : '暂无数据';
          that.downFloorArea = res.data.down_floor_area > 0 ? res.data.down_floor_area : '暂无数据';
        }
        if(type === 'room'){
          that.usedWays = res.data.used_ways;
          that.physicalFloor = res.data.physical_floor > 0 ? res.data.physical_floor : '暂无数据';
          that.orientation = res.data.orientation === null ? '暂无数据' : res.data.orientation;
          that.roomUnit = res.data.room_unit === null ? '暂无数据' : res.data.room_unit;
        }
      },function(err){});
    }
  },
};
</script>
<style scoped>
.history {
  margin-bottom: 10%;
}
.van-cell {
  text-align: left;
}
</style>