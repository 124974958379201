<template>
  <div id="app">
    <app-header v-if="$route.meta.show_public"></app-header>
    <router-view></router-view>
    <app-footer v-if="$route.meta.show_public"></app-footer>
  </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
  name: "app",
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  data() {
    return {
    };
  },
  created(){
    this.loginCheck()
  },
  methods: {
    loginCheck() {
      var that = this;
      if (
        !sessionStorage.getItem("user_open_id") ||
        sessionStorage.getItem("user_open_id") == null || sessionStorage.getItem('user_open_id') == undefined
      ) {
        this.$axios.get("/login_check").then(
          function (res) {
            if (res.data.code == "500" && that.$route.name !== "Login") {
              that.$toast.fail(res.data.msg);
              setTimeout(that.$router.push(res.data.url), 300);
            }
            if (res.data.code == "000") {
              if (
                sessionStorage.getItem("show_success") == false ||
                !sessionStorage.getItem("show_success")
              ) {
                that.$toast.success(res.data.msg);
                sessionStorage.setItem("show_success", true);
                sessionStorage.setItem('user_open_id',res.data.open_id);
              }
            }
          },
          function (err) {}
        );
      }else{
        if(sessionStorage.getItem('show_success') !== true){
          sessionStorage.setItem('show_success',true);
          that.$toast.success('登录成功!');
        }
      }
    },
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
