<template>
  <div class="home" style="margin-top:5px;">
    <van-notice-bar wrapable :scrollable="false" :text="userTips" />
    <van-form @submit="onSubmit">
      <van-field
        readonly
        clickable
        required
        name="area"
        :value="areaValue"
        label="地区选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
        :error-message="countyError"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area :area-list="areaList" value="350503" @confirm="onConfirm" @cancel="showArea = false" />
      </van-popup>
      <van-field
        required
        name="estate"
        label="小区选择"
        readonly
        :value="estateValue"
        :error-message="estateError"
      >
        <template #input>
          <a-select
            show-search
            autoFocus
            :value="value"
            placeholder="输入小区名称"
            style="width: 100%;"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearch"
            @change="handleChange"
          >
            <a-select-option v-for="d in data" :key="d.value">{{ d.text }}</a-select-option>
          </a-select>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        required
        name="building"
        :value="building"
        label="选择楼幢"
        placeholder="点击选择楼幢"
        @click="showBuilding = true"
      />
      <van-popup v-model="showBuilding" position="bottom">
        <van-picker
          title="选择楼幢"
          show-toolbar
          :columns="buildingList"
          @confirm="onConfirmBuilding"
          @cancel="showBuilding = false"
        />
      </van-popup>
      <!-- <van-field
        readonly
        clickable
        required
        name="room"
        :value="room"
        label="选择房号"
        placeholder="点击选择房号"
        @click="showRoom = true"
      />
      <van-popup v-model="showRoom" position="bottom">
        <van-picker
          title="选择房号"
          show-toolbar
          :columns="roomList"
          @confirm="onConfirmRoom"
          @cancel="showRoom = false"
        />
      </van-popup> -->
      <van-field
        required
        name="room"
        label="房号选择"
        readonly
        :value="room"
      >
        <template #input>
          <a-select
            show-search
            :value="room"
            placeholder="点击选择房号"
            style="width: 100%;"
            :default-active-first-option="false"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @search="handleSearchRoom"
            @change="handleChangeRoom"
          >
            <a-select-option v-for="d in roomList" :key="d.value">{{ d.text }}</a-select-option>
          </a-select>
        </template>
      </van-field>
      <van-field
        required
        v-model="roomArea"
        name="roomArea"
        type="number"
        label="面积"
        placeholder="房号选择完若面积为空,请手动输入."
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
    <van-popup v-model="show" closeable style="width:100%;height:100%;">
      <van-grid :border="false" :column-num="1">
        <van-grid-item>
          <div style="border:1px solid lightgray;width:100%;border-radius:5px;">
            <div
              style="background-color:dodgerblue;width:100%;height:150px;border-radius:5px 5px 0 0;"
            >
              <div style="text-align:center;color:white;padding-top:30px;font-size:15px;">抵押价评估总价:</div>
              <div
                style="text-align:center;color:white;font-weight:bolder;font-size:30px;"
              >{{totalPrice}}</div>
              <div style="text-align:center;font-size:13px;color:white;">查询时间:{{createdAt}}</div>
            </div>
            <div
              style="width:100%;height:20px;margin-top:5px;font-size:12px;text-align:center;display:flex;flex-direction:row;"
            >
              <div style="float:left;border-right:1px solid lightgray;flex:1">单价(元/㎡) {{unitPrice}}</div>
              <div style="text-align:center;flex:1">面积(㎡) {{buildingArea}}</div>
            </div>
          </div>
          <div style="width:100%;">
            <div style="background-color:lightgray;width:100%;">
              <span
                style="padding-left:10px;font-size:15px;font-weight:bold;text-align:left;"
              >评估对象信息:</span>
            </div>
            <van-cell-group>
              <van-cell title="小区" :value="eName" @click="jumpDetail(eId,'estate')" />
              <van-cell title="楼幢" :value="bNo" @click="jumpDetail(bId,'building')" />
              <van-cell title="房号" :value="rNo" @click="jumpDetail(rId,'room')" />
              <van-cell title="地址" :value="address" />
              <van-cell title="年份" :value="year" />
              <van-cell title="楼层" :value="floor" />
            </van-cell-group>
          </div>
        </van-grid-item>
      </van-grid>
    </van-popup>
    <van-popup v-model="targetDetailShow" closeable style="width:100%;height:100%;">
      <van-grid :border="false" :column-num="1">
        <van-grid-item>
          <div
            style="margin:0 0 10px 0;width:100%;"
            v-if="feType === 'estate' || feType === 'building'"
          >
            <div style="height:300px;width:100%;font-weight:bold;text-align:center;" class="map">
              <iframe
                frameborder="no"
                border="0"
                style="width:100%;height:100%;margin:0 auto;"
                :src="ifSrc"
              ></iframe>
            </div>
          </div>
          <div style="width:100%;">
            <van-cell-group v-if="feType === 'estate'">
              <van-cell title="小区名称" :value="eName" @click="jumpDetail(eId,'estate')" />
              <van-cell title="开发商" :value="developer" @click="jumpDetail(bId,'building')" />
              <van-cell title="物业公司" :value="propertyName" @click="jumpDetail(rId,'room')" />
              <van-cell title="小区地址" :value="address" />
              <van-cell title="用地面积" :value="buildingArea" />
            </van-cell-group>
            <van-cell-group v-if="feType === 'building'">
              <van-cell title="小区名称" :value="eName" @click="jumpDetail(eId,'estate')" />
              <van-cell title="楼幢编号" :value="bNo" @click="jumpDetail(bId,'building')" />
              <van-cell title="是否电梯" :value="limitNum" @click="jumpDetail(rId,'room')" />
              <van-cell title="建筑年代" :value="year" />
              <van-cell title="楼房结构" :value="structure" />
              <van-cell title="地上面积" :value="upFloorArea" />
              <van-cell title="地下面积" :value="downFloorArea" />
            </van-cell-group>
            <van-cell-group v-if="feType === 'room'">
              <van-cell title="小区名称" :value="eName" @click="jumpDetail(eId,'estate')" />
              <van-cell title="楼幢编号" :value="bNo" @click="jumpDetail(bId,'building')" />
              <van-cell title="房号" :value="rNo" @click="jumpDetail(rId,'room')" />
              <van-cell title="面积" :value="buildingArea" />
              <van-cell title="用途" :value="usedWays" />
              <van-cell title="逻辑楼层" :value="floor" />
              <van-cell title="物理楼层" :value="physicalFloor" />
              <van-cell title="房屋朝向" :value="orientation" />
              <van-cell title="户型" :value="roomUnit" />
            </van-cell-group>
          </div>
        </van-grid-item>
      </van-grid>
    </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import AreaList from "../assets/Area.json";
import querystring from "querystring";
import qs from "qs";
let timeout;
let currentValue;
let roomtimeout;
let roomcurrentValue;

export default {
  name: "Home",
  data() {
    return {
      areaValue: "福建省-泉州市-丰泽区",
      estateValue: "",
      showArea: false,
      showBuilding: false,
      showRoom: false,
      show: false,
      targetDetailShow: false,
      countyCode: "350503",
      cityCode: "350500",
      provinceCode: "350000",
      areaList: AreaList,
      building: "",
      buildingValue: "",
      buildingList: [],
      room: "",
      uId: "",
      roomValue: "",
      roomList: [],
      estateList: "",
      data: [],
      value: undefined,
      roomArea: "",
      countyError: "",
      estateError: "",
      areaError: "",
      userTips: "",
      eName: "",
      bNo: "",
      rNo: "",
      eId: "",
      bId: "",
      rId: "",
      address: "",
      year: "",
      floor: "",
      totalPrice: "",
      createdAt: "",
      unitPrice: "",
      buildingArea: "",
      feId: "",
      feType: "",
      ifSrc: "",
      propertyName: "",
      developer: "",
      limitNum: "",
      structure: "",
      upFloorArea: "",
      downFloorArea: "",
      usedWays: "",
      physicalFloor: "",
      orientation: "",
      roomUnit: "",
      showNumKeyboard: false,
    };
  },
  created() {
    sessionStorage.setItem("user_open_id", this.$route.query.openid);
  },
  mounted() {
    // this.loginCheck();
    this.getUserInfo();
    // this.getDefaultArea();
  },
  methods: {
    // getDefaultArea(){
    //    this.areaValue = '福建省-泉州市-丰泽区';
    //    this.countyCode = '350503';
    //    this.cityCode = '350500';
    //    this.provinceCode = '350000';
    // },
    getUserInfo() {
      var that = this;
      this.$axios.get("/center").then(
        function (response) {
          if(response.code == '500'){
            that.$toast.fail(response.msg);
            that.$router.push(response.url);
          }else{
          that.userTips =
            response.data.mobile +
            "，剩余" +
            response.data.authorize_times +
            "次，" +
            response.data.authorize_end_times +
            "授权到期!";
          that.uId = response.data.u_id;
          }
        },
        function (err) {
          console.log(err);
        }
      );
    },
    handleSearch(value) {
      var that = this;
      this.fetch(value, (data) => (this.data = data));
      // fetch(value, (data) => (this.data = data));
    },
    handleChange(value) {
      this.value = value;
      this.estateValue = value;
      var that = this;
      this.fetch(value, (data) => (this.data = data));
      // fetch(value, (data) => (this.data = data));
      this.$axios.get("/building?estate=" + value).then(
        function (response) {
          that.buildingList = response.data;
          that.building = "";
          that.buildingValue = "";
          that.room = "";
          that.roomValue = "";
          that.roomArea = "";
        },
        function (err) {}
      );
    },
     //>小区名搜索
    fetch(value, callback) {
      var that = this;
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      currentValue = value;

      function fake() {
        const str = querystring.encode({
          code: "utf-8",
          q: value,
          area: that.countyCode,
        });
        that.$axios.get("/estate?" + str).then(
          function (response) {
            if (currentValue === value) {
              const data = [];
              var result = response.data;
              result.forEach((r) => {
                data.push({
                  value: r.id,
                  text: r.text,
                });
              });
              that.data = data;
            }
          },
          function (err) {}
        );
      }
      timeout = setTimeout(fake, 300);
    },
    //>房号搜索
    handleSearchRoom(value) {
      var that = this;
      this.fetchRoom(value, (data) => (this.data = data));
      // fetch(value, (data) => (this.data = data));
    },
    handleChangeRoom(value) {
      this.room = value;
      this.roomValue = value;
      var that = this;
      this.fetchRoom(value, (data) => (this.data = data));
      // fetch(value, (data) => (this.data = data));
      this.$axios.get("/room_area?room_id=" + this.roomValue).then(
        function (response) {
          that.roomArea =
            response.data == 0 || response.data == null ? null : response.data;
        },
        function (err) {}
      );
    },
    fetchRoom(value, callback) {
      var that = this;
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      roomcurrentValue = value;

      function fake() {
        const str = querystring.encode({
          code: "utf-8",
          q: value,
          building: that.buildingValue,
        });
        that.$axios.get("/room?" + str).then(
          function (response) {
            if (roomcurrentValue === value) {
              const data = [];
              var result = response.data;
              result.forEach((r) => {
                data.push({
                  value: r.id,
                  text: r.text,
                });
              });
              that.roomList = data;
            }
          },
          function (err) {}
        );
      }
      roomtimeout = setTimeout(fake, 300);
    },
    //>省市区确认
    onConfirm(values) {
      this.areaValue = values.map((item) => item.name).join("-");
      this.countyCode = values[2].code;
      this.cityCode = values[1].code;
      this.provinceCode = values[0].code;
      this.showArea = false;
    },
    //>楼幢选择确认
    onConfirmBuilding(values) {
      this.building = values.text;
      this.buildingValue = values.value;
      this.showBuilding = false;
      this.roomList = '';
      this.room = "";
      this.roomValue = "";
      this.roomArea = "";
    },
    //>房号选择确认
    // onConfirmRoom(values) {
    //   this.room = values.text;
    //   this.roomValue = values.value;
    //   var that = this;
    //   this.$axios.get("/room_area?room_id=" + this.roomValue).then(
    //     function (response) {
    //       that.roomArea =
    //         response.data == 0 || response.data == null ? null : response.data;
    //     },
    //     function (err) {}
    //   );
    //   this.showRoom = false;
    // },
    
    //>提交
    onSubmit(values) {
      values.building = this.buildingValue;
      values.room = this.roomValue;
      values.uId = this.uId;
      values.countyCode = this.countyCode;
      values.cityCode = this.cityCode;
      values.provinceCode = this.provinceCode;
      var that = this;
      this.$axios.post("/evaluate", qs.stringify(values)).then(
        function (response) {
          if (response.data.code == "500") {
            that.$toast({
              message: response.data.msg,
              icon: "warn-o",
            });
          } else {
            var those = that;
            that.show = true;
            that.$axios.get("/evaluator_detail?fe_id=" + response.data.id).then(
              function (res) {
                those.eId = res.data.e_id;
                those.bId = res.data.b_id;
                those.rId = res.data.r_id;
                those.eName = res.data.e_name;
                those.bNo = res.data.building_no;
                those.rNo = res.data.room_no;
                those.address =
                  res.data.address === null ? "暂无数据" : res.data.address;
                those.year = res.data.building_year;
                those.floor =
                  res.data.logic_floor > 0 ? res.data.logic_floor : "暂无数据";
                those.totalPrice = res.data.total_price;
                those.createdAt = res.data.created_at;
                those.unitPrice = res.data.unit_price;
                those.buildingArea =
                  res.data.building_area > 0
                    ? res.data.building_area
                    : "暂无数据";
              },
              function (err) {
                console.log(err);
              }
            );
          }
        },
        function (err) {}
      );
    },
    jumpDetail(id, type) {
      this.targetDetailShow = true;
      this.feId = id;
      this.feType = type;
      this.ifSrc =
        "https://www.321pg.com/mobile/mobile_map?id=" + id + "&type=" + type;
      var that = this;
      this.$axios.get("/target_detail?id=" + id + "&type=" + type).then(
        function (res) {
          if (type === "estate") {
            that.propertyName = res.data.property_name;
            that.developer = res.data.developer;
          }
          if (type === "building") {
            that.limitNum = res.data.limit_num > 0 ? "是" : "否";
            that.structure = res.data.structure;
            that.upFloorArea =
              res.data.up_floor_area > 0 ? res.data.up_floor_area : "暂无数据";
            that.downFloorArea =
              res.data.down_floor_area > 0
                ? res.data.down_floor_area
                : "暂无数据";
          }
          if (type === "room") {
            that.usedWays = res.data.used_ways;
            that.physicalFloor =
              res.data.physical_floor > 0
                ? res.data.physical_floor
                : "暂无数据";
            that.orientation =
              res.data.orientation === null ? "暂无数据" : res.data.orientation;
            that.roomUnit =
              res.data.room_unit === null ? "暂无数据" : res.data.room_unit;
          }
        },
        function (err) {}
      );
    },
  },
};
</script>
<style>
.ant-select-selection {
  border: 1px solid white !important;
}
.ant-select-selection__rendered {
  margin-left: 0 !important;
}
.van-cell {
  text-align: left;
}
</style>