<template>
  <div class="history">
    <van-form @submit="onSubmit">
      <van-field
        v-model="message"
        rows="5"
        clearable
        label-align="center"
        autosize
        border
        name="feed_back"
        label
        type="textarea"
        placeholder="请输入反馈内容，如：XX小区X号楼XXX室房产缺失；XX小区X号楼XXX室结果有明显误差；等等。我们将及时处理，感谢你的帮助！"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import qs from "qs";
export default {
  name: "History",
  data() {
    return {
      message: "",
    };
  },
  created(){

  },
  methods: {
    onSubmit(values) {
      var that = this;
      this.$axios.post("/save_feed", qs.stringify(values)).then(
        function (res) {
          if(res.data.code == '000'){
            that.$toast.success(res.data.msg);
            setTimeout(window.location.reload(),1000);
          }else{
            that.$toast.fail(res.data.msg);
            setTimeout(window.location.reload(),1000);
          }
        },
        function (err) {}
      );
    },
  },
};
</script>