<template>
  <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
</template>

<script>
export default {
  data(){
    return {
      title:'新瑞图快评系统'
    }
  },
  // created() {
  //   this.getTitle();
  // },
  methods: {
    // getTitle() {
    //   var that = this;
    //   this.$axios.get("http://xrt.com/api/title").then(
    //     function (response) {
    //       that.title = response.data;
    //     },
    //     function (err) {
    //       console.log(err);
    //     }
    //   );
    // },
    onClickLeft() {
      if (window.history.length > 1) {
        history.go(-1);
      } else {
        history.go("/");
      }
    },
  },
};
</script>