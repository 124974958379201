import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import History from '../views/History.vue'
import FeedBack from '../views/Feed_Back.vue'
import Me from '../views/Me.vue'
import MyInfo from '../views/MyInfo.vue'
import Login from '../views/Login.vue'
// import Vant from 'vant'

// Vue.use(Vant)
Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      show_public:true,
      title:'新瑞图快评系统|首页'
    }
  },
  {
    path: '/history',
    name: 'History',
    meta:{
      show_public:true,
      title:'新瑞图快评系统|历史'
    },
    component: History
  },
  
  {
    path: '/feed_back',
    name: 'FeedBack',
    component:FeedBack,
    meta:{
      show_public:true,
      title:'新瑞图快评系统|反馈'
    }
  },
  {
    path: '/me',
    name: 'Me',
    component: Me,
    meta:{
      show_public:true,
      title:'新瑞图快评系统|个人中心'
    }
  },
  {
    path: '/me/info',
    name:'Me',
    component:MyInfo,
    meta:{
      show_public:true,
      title:'新瑞图快评系统|修改信息'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component:Login,
    meta:{
      show_public:false,
      title:'新瑞图快评系统|登录'
    }
  },
]

const router = new VueRouter({
  mode:'history',
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

export default router
